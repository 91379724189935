import * as React from "react"
import {useState, useEffect} from "react"
import { Helmet } from "react-helmet"
import { graphql } from 'gatsby'
import i18n from '../components/i18n'
import { withTranslation, useTranslation } from 'react-i18next';
import Link from "../components/link"
import Layout from "../components/layout"
import NavigationHelper from "../helpers/navigation"
import Seo from '../components/seo'

/** Blog dependencies */
import BlogListItem from '../components/blog_list_item'
import BlogListSidebar from "../components/blog_list_sidebar"
import BlogConf from '../conf/blog.json'
import BlogTags from "../blog/tags.json"
import BlogCategories from "../blog/categories.json"

/** Images import */
import img_slider1 from "../images/a1_blog_header_banner_bg.webp"

// markup
const BlogIndexPage = (props) => {
  const [activeLanguage, setActiveLanguage] = useState()
  useEffect(() => { setActiveLanguage(i18n.language); }, [setActiveLanguage])

  const { t } = useTranslation(['main', 'forms']);
  const LocalizedLink = withTranslation('main')(Link)

  // Navigation info
  const pageInfo = {
    key: "blog",
    lang: i18n.language
  }

  // console.log('this.props',this.props)
  const posts = [];
  const BlogPostsData = props.data.allArticlesJson.edges;
  BlogPostsData.map((blogPost, index)=> {
    const element = blogPost.node;
    // We only consider published posts
    if (element.currentState != "PUBLISHED")
      return

    const publishDate = new Date(element.publishDate);
    const category = BlogCategories[element.contentGroupId]["code"];
    const categoryTitle =  BlogCategories[element.contentGroupId][activeLanguage];

    // Calculate the post's age
    const today = new Date();
    const age = Math.ceil((today - publishDate) / (1000 * 3600 * 24));

    posts.push(<BlogListItem key={index}
      title ={element.htmlTitle}
      category = {category}
      categoryTitle = {categoryTitle}
      slug = {element.slug}
      date = {publishDate.toLocaleDateString()}
      preview_url = {element.featuredImage}
      preview_alt = {element.featuredImageAltText}
      link_title = {t('main:blog_read_article_link_title', {post_title: element.htmlTitle})}
      excerpt = {element.postSummary}
      tags = {element.tagIds}
      isNew={age < parseInt(BlogConf.newPostMaxAge) ? true : false}
      language={i18n.language}
      readMore={t('main:blog_list_read-more_btn')}
    />)
  });

  return (
    <Layout
      location={props.location}
      pageInfo={pageInfo}
    >
      <Helmet
        bodyAttributes={{
          class: 'testi-monial-main-page blog'
      }}
      >
          <title>{t('main:testimonials_main_content_title' )}</title>
      </Helmet>

      <Seo
        description={t('main:blog_meta_seo-description_txt')}
        title={t('main:blog_meta_seo-title_txt' )}
        keywords={[
          t('main:blog_meta_seo-keyword1_txt'),
          t('main:blog_meta_seo-keyword2_txt'),
          t('main:blog_meta_seo-keyword3_txt'),
          t('main:blog_meta_seo-keyword4_txt'),
          t('main:blog_meta_seo-keyword5_txt'),
          t('main:blog_meta_seo-keyword6_txt'),
          t('main:blog_meta_seo-keyword7_txt')
        ]}
        url={props.location.href}
        image_url={`${props.location.origin}/a1_cloud_logo.png`}
      />

      <div className="main-content">
    {/* breadcumbs */}
    <div className="site-breadcumbs">
      <div className="container">
        <small>
          <a href={NavigationHelper.useGeneratePageURL(activeLanguage, 'home')} title={t('main:global_header_home_menu-item-seo-title_txt')}>
            A1:
          </a>{" "}
          // {t('main:blog_header_breadcrumb_title')}
        </small>
      </div>
    </div>
    {/* end breadcumbs */}

    <div
    className="service_header"
    style={{ backgroundImage: `url(${img_slider1})` }}
  >
    <div className="container">
      <div className="title_block">
        <h1 className="nos-service"> {t('main:blog_category_list_main_content_title')}</h1>
      </div>
    </div>
  </div>
  <div className="blog-main-contan service-page-main-contant">
    <div className={`container ${activeLanguage}`}>

      <div className="row new-mobile-slider desktop_view">
      <BlogListSidebar
        sidebarNavTitle={t('main:blog_list_sidebar_navigation_title')}
        sidebarCategoriesTitle={t('main:blog_list_sidebar_categories_title')}
        sidebarTagsTitle={t('main:blog_list_sidebar_tags_title')}
        shareTitle={t('main:global_main_share_label')}
        language={i18n.language}
        tags={BlogTags}
      />
        { posts.length &&
          posts
        }
      </div>
      <div className="row new-mobile-slider mobile_view">
        { posts }
      </div>

    </div>

    <div className="listBottomButtons">
      {/* previousPageLink and nextPageLink were added by the plugin */ }
      <a
        className={props.pageContext.previousPagePath ? 'btn' : 'd-none'}
        href={NavigationHelper.addTrailingSlash(props.pageContext.previousPagePath)}
      >
        {t('main:blog_list_previous_page_btn')}
      </a>
      <a
        className={props.pageContext.nextPagePath ? 'btn' : 'd-none'}
        href={NavigationHelper.addTrailingSlash(props.pageContext.nextPagePath)}
      >
        {t('main:blog_list_next_page_btn')}
      </a>
    </div>
  </div>

</div>
  {/* main content end */}
    </Layout>
  )
}

export const query = graphql`
query BlogQuery($skip: Int!, $limit: Int!){
  allArticlesJson(
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        id
        name
        language
        htmlTitle
        slug
        featuredImage
        featuredImageAltText
        publishDate
        currentState
        contentGroupId
        tagIds
        postSummary
      }
    }
  }
}

`

export default BlogIndexPage
